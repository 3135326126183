const basePath = ''

const app = {
  routerName: {
    home: 'Invitation',
    wedding: 'Wedding'
  }
}
const pages = {
  home: '/',
  wedding: '/wedding'
}

const api = {
  testGetAPI: {
    api: '/backend/test'
  },
  testPostAPI: {
    api: '/backend/test'
  },
  getApiPath: function (apiPath) {
    return basePath + apiPath
  }
}

export { app, pages, api }
