<template>
  <main>
    <router-view />
    <div
      :class="['play-button', { 'play-button--playing': musicPlaying }]"
      @click="togglePlay"
    >
      <img
        alt="player"
        src="@/assets/images/playred1.svg"
      >
    </div>
  </main>
</template>

<script src="./js/app.js"></script>

<style lang="scss" scoped>
@import "@/assets/scss/components";

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

.play-button {
  position: fixed;
  z-index: 51;
  cursor: pointer;
  bottom: 35px;
  width: 60px;
  left: 35px;

  & > img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }

  &--playing {
    animation: rotate 3s linear infinite;
  }

  @include touch {
    left: 17px;
  }
}
</style>
