import AOS from 'aos'

export default {
  data () {
    return {
      musicPlaying: true
    }
  },
  mounted () {
    AOS.init()
  },
  methods: {
    audioAutoplay () {
      const element = document.getElementById('bgm')
      element.play()
    },
    togglePlay () {
      const element = document.getElementById('bgm')
      if (this.musicPlaying) {
        element.pause()
        this.musicPlaying = false
        return
      }
      element.play()
      this.musicPlaying = true
    }
  }
}
