import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import VueFeather from 'vue-feather'

import '@api-mock'

import 'aos/dist/aos.css'
import './main.scss'

const app = createApp(App)
app.component(VueFeather.name, VueFeather)

app.use(createPinia())
app.use(router)

const useImage = (url) => {
  return new URL(`/src/${url}`, import.meta.url).href
}
app.config.globalProperties.$image = useImage

app.mount('#app')
