import { createRouter, createWebHistory } from 'vue-router'
import { app, pages } from '@/config'

const HomePage = () => import(
  /* webpackChunkName: "p-home-page" */ '@/pages/HomePage.vue')

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: pages.wedding
    },
    {
      path: pages.wedding,
      name: app.routerName.wedding,
      component: HomePage
    }
  ]
})

router.beforeEach((to, from, next) => {
  // document.title = to.meta.title
  next()
})

export default router
